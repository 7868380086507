
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { lightFormat } from "date-fns";
import { DependencyContainer } from "tsyringe";

import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { LITE_FEATURE, ThermometerDetails, ThermometerItem } from "@/domain";

import { IThermometerService } from "@/services";

@Component({
  filters: {
    pretty(date: Date): string {
      return lightFormat(date, "dd/MM/yyyy");
    },
  },
  data: () => {
    return {
      EMPTY,
    };
  },
  components: {
    TableUi,
  },
})
export default class ThermometersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get thermometerService() {
    return this.container.resolve<IThermometerService>(S.THERMOMETER_SERVICE);
  }

  get existingSerialNumbers() {
    return _.map(this.thermometers, (t) => t.serialNumber);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/ThermometerCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/ThermometerUpdate.vue");
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Validité",
        align: "center",
        sortable: false,
        value: "prettyValidityDate",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  thermometers: ThermometerItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.thermometers = await this.thermometerService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(thermometer: ThermometerDetails) {
    this.thermometers.push(ThermometerItem.fromDetails(thermometer));
  }

  update(thermometer: ThermometerDetails) {
    const index = _.findIndex(this.thermometers, (t) => t.id == thermometer.id);
    if (index != -1) {
      this.thermometers.splice(
        index,
        1,
        ThermometerItem.fromDetails(thermometer)
      );
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const thermometer = _.find(this.thermometers, (t) => t.id == id)!;
      const result = await this.thermometerService.update(id, {
        serialNumber: thermometer.serialNumber!,
        equipmentType: thermometer.equipmentType!,
        equipmentBrandId: thermometer.equipmentBrand.id!,
        validityDate: lightFormat(thermometer.validityDate!, "yyyy-MM-dd"),
        disabled: !thermometer.disabled,
      });

      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
